import { enumManagers } from '@chirp/enums';
import { sortBy } from 'lodash';
import React from 'react';

import EnumTag, { Color } from '../../components/EnumTag';
import { UnitOccupancy } from '../../graphql/api/generated';
import {
  PermissionScope_enum,
  PropertyLabelFragment,
  SmartLockLabelFragment,
  UnitGroupLabelFragment,
  UnitType_enum,
} from '../../graphql/hasura/generated';
import { PropertyModel } from '../Property/model';
import { SmartLockModel } from '../SmartLock/model';
import { UnitGroupModel } from '../UnitGroup/model';
import { UserRoleModel } from '../UserRole/model';

import model from './model';

export const propertyColumn = model.createColumn<{ property: PropertyLabelFragment }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: PropertyModel,
    path: 'property',
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ property }) => PropertyModel.routes.renderRowLink(property),
});

export const unitNumberColumn = model.createColumn<{ unitNumber: string }>({
  dataIndex: 'unitNumber',
});

export const buildingColumn = model.createColumn<{ building?: string | null }>({
  dataIndex: 'building',
});

export const typeColumn = model.createColumn<{ type?: UnitType_enum | null }>({
  title: 'Type',
  dataIndex: 'type',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'UnitType',
  },
  render: ({ type }) => type ? enumManagers.UnitType.getLabel(type) : null,
});

export const unitGroupColumn = model.createColumn<{ unitGroup?: UnitGroupLabelFragment | null }>({
  filterOptions: {
    type: 'RELATIONSHIP',
    model: UnitGroupModel,
    path: 'unitGroup',
  },
  render: ({ unitGroup }) => UnitGroupModel.routes.renderRowLink(unitGroup),
});

export const sourceIdColumn = model.createColumn<{ sourceId?: string | null }>({
  title: 'Source ID',
  dataIndex: 'sourceId',
});

function getOccupancyFromCount(occupantCount: number | null | undefined) {
  return typeof occupantCount === 'number' && occupantCount > 0
    ? UnitOccupancy.OCCUPIED
    : UnitOccupancy.VACANT;
}

export const occupancyColumn = model.createColumn<{ occupantCount?: number | null }>({
  title: 'Occupancy',
  enabled: () => UserRoleModel.permissions.canRead(),
  render: ({ occupantCount }) => {
    return (
      <EnumTag
        enumValue={getOccupancyFromCount(occupantCount)}
        colorMap={{
          [UnitOccupancy.OCCUPIED]: Color.Green,
          [UnitOccupancy.VACANT]: Color.Orange,
        }}
      />
    );
  },
  renderString: ({ occupantCount }) => getOccupancyFromCount(occupantCount),
});

export const totalSmartLocksColumn = model.createColumn<{ smartLocks: SmartLockLabelFragment[] }>({
  title: 'Total Smart Locks',
  enabled: () => SmartLockModel.permissions.canRead(),
  render: ({ smartLocks }) => {
    return smartLocks.length !== 0 ? smartLocks.length : null;
  },
});

export const smartLocksColumn = model.createColumn<{ smartLocks: SmartLockLabelFragment[] }>({
  title: 'Smart Locks',
  filterOptions: {
    type: 'RELATIONSHIP',
    model: SmartLockModel,
    path: 'smartLocks',
  },
  render: ({ smartLocks }) => {
    const sortedSmartLocks = sortBy(smartLocks, s => s.name);

    return SmartLockModel.routes.renderRowLinks(sortedSmartLocks);
  },
  renderString: ({ smartLocks }) => {
    const sortedSmartLocks = sortBy(smartLocks, s => s.name);

    return sortedSmartLocks.map(s => SmartLockModel.labels.getLabel(s)).join(', ');
  },
});
